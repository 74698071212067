<template>
 <div>
  <CRow>
   <CCol md="12">
    <CCard>
     <CCardHeader>
      <span style="line-height: 35px"
       ><strong>Ekstra Ürün & Siğorta Ara</strong></span
      >
      <div class="card-header-actions">
       <CButton
        color="success"
        @click="
         cleanProductModalAddUpdateForm(),
          (productModalAddUpdateOptions.process = 'add'),
          (productModalAddUpdateOptions.title = 'Ürün Ekle'),
          (productModalAddUpdate = true)
        "
        class="mr-1"
        >Yeni Ürün Ekle
       </CButton>
       <CLink
        class="card-header-action btn-minimize"
        @click="searchProductFormCollapsed = !searchProductFormCollapsed"
       >
        <CIcon
         :name="`cil-chevron-${searchProductFormCollapsed ? 'bottom' : 'top'}`"
        />
       </CLink>
      </div>
     </CCardHeader>
     <CCollapse :show="searchProductFormCollapsed">
      <CCardBody>
       <CForm @submit.prevent="searchProduct">
        <CRow>
         <CCol sm="3">
          <CSelect
           label="Durum"
           :value.sync="searchProductForm.status"
           :options="searchProductFormStatusOptions"
          />
         </CCol>
         <CCol sm="3">
          <CSelect
           label="Tip"
           :value.sync="searchProductForm.type"
           :options="searchProductType"
          />
         </CCol>
         <CCol sm="3">
          <CInput label="Ürün Adı" v-model="searchProductForm.name" />
         </CCol>
         <CCol sm="12" md="2" class="mt-md-4">
          <CButton
           type="submit"
           color="info"
           class="px-3"
           style="margin-top: 4px"
           >Ara
          </CButton>
         </CCol>

         <CCol sm="3">
          <CInput label="Ürün Kodu" v-model="searchProductForm.productCode" />
         </CCol>
         <CCol sm="3">
          <CSelect
           label="Web Sitesi Satış"
           :value.sync="searchProductForm.webSaleStatus"
           :options="searchWebSaleStatus"
          />
         </CCol>

         <CCol sm="3">
          <CSelect
           label="Web Sitesi Ücretsiz Satış"
           :value.sync="searchProductForm.webSaleFree"
           :options="searchWebSaleStatus"
          />
         </CCol>
        </CRow>
       </CForm>
      </CCardBody>
     </CCollapse>
    </CCard>
   </CCol>
   <CCol md="12" v-if="productGridShow">
    <CCard>
     <CCardHeader>
      <span><strong>Ürünler</strong></span>
     </CCardHeader>
     <CCardBody class="p-0">
      <ag-grid-vue
       style="width: 100%; height: 500px"
       class="ag-theme-balham"
       :columnDefs="columnDefs"
       :defaultColDef="defaultColDef"
       :rowData="rowData"
       :sideBar="sideBar"
       :getContextMenuItems="getGridContextMenuItems"
       :rowSelection="rowSelection"
       :enableRangeSelection="true"
       :statusBar="statusBar"
       @grid-ready="onGridReady"
      >
      </ag-grid-vue>
     </CCardBody>
    </CCard>
   </CCol>
  </CRow>

  <CModal
   :title="productModalAddUpdateOptions.title"
   size="lg"
   :show.sync="productModalAddUpdate"
   class="carModalAddUpdateForm"
  >
   <CRow class="mt-lg-2">
    <CCol sm="4">
     <CSelect
      label="Durum"
      :value.sync="productModalForm.status"
      :options="modalProductFormStatusOptions"
     />
    </CCol>
    <CCol sm="4">
     <CSelect
      label="Tip"
      :value.sync="productModalForm.type"
      :options="modalProductType"
     />
    </CCol>
    <CCol sm="4">
     <div style="width: auto; float: right">
      <label
       for="free"
       style="padding-right: 5px; vertical-align: middle !important"
       >Ücretsiz
      </label>
      <input
       type="checkbox"
       id="free"
       value="Jack"
       style="vertical-align: baseline !important"
       v-model="productModalForm.webSaleFree"
      />
     </div>

     <CSelect
      label="Web Sitesi Satış"
      :value.sync="productModalForm.webSaleStatus"
      :options="searchWebSaleStatus"
     />
    </CCol>
    <CCol sm="4">
     <CInput label="Ürün Adı" v-model="productModalForm.name" />
    </CCol>
    <CCol sm="4">
     <CInput label="Adet Fiyatı" v-model="productModalForm.price" />
    </CCol>
    <CCol sm="4">
     <CInput
      type="number"
      min="0"
      label="Satış Adeti"
      v-model="productModalForm.saleCount"
     />
    </CCol>
    <CCol sm="4">
     <CInput label="Ürün Kodu" v-model="productModalForm.productCode" />
    </CCol>
    <CCol md="8">
     <CSelect
      label="Eşlenecek Ürün"
      :value.sync="productModalForm.matchProduct"
      :options="productList"
     />
    </CCol>
    <CCol sm="12">
     <CInput label="Açıklama" v-model="productModalForm.description" />
    </CCol>
   </CRow>
   <template #footer>
    <CButton
     color="success"
     v-if="productModalAddUpdateOptions.process == 'add'"
     :disabled="productModalAddUpdateBtnDisable"
     @click="productAddUpdate()"
     >Ekle</CButton
    >
    <CButton
     color="success"
     v-if="productModalAddUpdateOptions.process == 'update'"
     :disabled="productModalAddUpdateBtnDisable"
     @click="productAddUpdate()"
     >Güncelle</CButton
    >
   </template>
  </CModal>
 </div>
</template>

<script>
import Func from "@/func";
import axios from "axios";
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";

export default {
 name: "search",
 data() {
  return {
   productModalAddUpdate: false,
   productsGridShow: false,
   searchProductFormCollapsed: true,
   productGridShow: false,
   productModalAddUpdateBtnDisable: false,
   productList: [],
   rowData: [],
   searchProductForm: {
    status: "",
    name: "",
    type: "",
    productCode: "",
    webSaleStatus: "",
    webSaleFree: "",
   },
   productModalForm: {
    _id: "",
    status: "",
    name: "",
    productSlug: "",
    productCode: "",
    type: "",
    price: 0,
    saleCount: 0,
    webSaleStatus: "",
    webSaleFree: false,
    description: "",
    matchProduct: "",
   },
   searchProductFormStatusOptions: [
    {
     value: "",
     label: "Tümü",
    },
    {
     value: true,
     label: "Aktif",
    },
    {
     value: false,
     label: "Pasif",
    },
   ],
   modalProductFormStatusOptions: [
    {
     value: "",
     label: "Seçiniz",
    },
    {
     value: true,
     label: "Aktif",
    },
    {
     value: false,
     label: "Pasif",
    },
   ],
   searchProductType: [
    {
     value: "",
     label: "Tümü",
    },
    {
     value: 1,
     label: "Ekstra Ürün",
    },
    {
     value: 2,
     label: "Siğorta",
    },
   ],
   modalProductType: [
    {
     value: "",
     label: "Seçiniz",
    },
    {
     value: 1,
     label: "Ekstra Ürün",
    },
    {
     value: 2,
     label: "Siğorta",
    },
   ],
   searchWebSaleStatus: [
    {
     value: "",
     label: "Seçiniz",
    },
    {
     value: true,
     label: "Aktif",
    },
    {
     value: false,
     label: "Pasif",
    },
   ],
   productModalAddUpdateOptions: {
    process: "",
    title: "",
   },
   rowSelection: "multiple",
   columnDefs: [
    {
     field: "status",
     cellRenderer: (params) => {
      return params.value ? "Aktif" : "Pasif";
     },
     cellStyle: (params) => {
      return params.value
       ? { color: "#2eb85c", fontWeight: "bold" }
       : { color: "#e55353", fontWeight: "bold" };
     },
     headerName: "Durum",
     width: 100,
    },
    {
     field: "name",
     headerName: "Ürün Adı",
     width: 220,
    },
    {
     field: "price",
     headerName: "Fiyat",
     width: 220,
    },
    {
     field: "productSlug",
     headerName: "Ürün Slug",
     width: 220,
    },
    {
     field: "matchProduct",
     headerName: "Eşleştirilen Ürün Id",
     width: 220,
    },
    {
     field: "productCode",
     headerName: "Ürün Kodu",
     width: 120,
    },
    {
     field: "saleCount",
     headerName: "Satış Adeti",
     width: 120,
    },
    {
     field: "type",
     cellRenderer: (params) => {
      return params.value == 1 ? "Ekstra Ürün" : "Sigorta";
     },
     headerName: "Ürün Tipi",
     width: 130,
    },
    {
     field: "webSaleStatus",
     cellRenderer: (params) => {
      return params.value ? "Aktif" : "Pasif";
     },
     headerName: "Online Satış Durumu",
     width: 200,
    },
    {
     field: "webSaleFree",
     cellRenderer: (params) => {
      return params.value ? "Aktif" : "Pasif";
     },
     headerName: "Online Ücretsiz Satış",
     width: 200,
    },
   ],
   defaultColDef: {
    sortable: true,
    resizable: true,
    filter: true,
   },
   statusBar: {
    // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
    statusPanels: [
     {
      statusPanel: "agTotalAndFilteredRowCountComponent",
      align: "left",
     },
     {
      statusPanel: "agTotalRowCountComponent",
      align: "center",
     },
     { statusPanel: "agFilteredRowCountComponent" },
     { statusPanel: "agSelectedRowCountComponent" },
     { statusPanel: "agAggregationComponent" },
    ],
   },
   sideBar: {
    // Grid yan çubuk tanımlaması
    toolPanels: [
     {
      id: "columns",
      labelDefault: "Sütunlar",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
     },
     ,
     {
      id: "filters",
      labelDefault: "Filtreler",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
     },
    ],
    defaultToolPanel: "",
   },
  };
 },
 components: {
  AgGridVue,
 },
 watch: {
  "productModalForm.name": function () {
   if (this.productModalForm.name != "") {
    this.productModalForm.productSlug = Func.slugify(
     this.productModalForm.name
    );
   } else {
    this.productModalForm.productSlug = "";
   }
  },
 },
 created() {
  this.getProduct();
 },
 methods: {
  onGridReady(params) {
   // Grid ready olduğunda
   this.gridApi = params.api;
   this.gridColumnApi = params.columnApi;
  },
  getGridContextMenuItems(params) {
   var _this = this;
   var result = [
    {
     name: "Ürünü Sil",
     action: function () {
      if (params.node.data._id) {
       Vue.swal({
        icon: "error",
        title: "Ürün Sil",
        html:
         "<strong>" +
         params.node.data.name +
         "</strong> isimli ürünü silmek istiyormusunuz ?",
        showDenyButton: true,
        confirmButtonText: "Evet",
        denyButtonText: "Hayır",
       }).then((result) => {
        if (result.isConfirmed) {
         axios
          .delete(
           process.env.VUE_APP_API_URL +
            "admin/vendor/product/" +
            params.node.data._id
          )
          .then((response) => {
           if (response.data.result == "success") {
            Vue.swal({
             icon: response.data.result,
             title: "Başarılı",
             html: response.data.message,
             confirmButtonText: "Tamam",
            });
            _this.searchProduct();
           } else {
            Vue.swal({
             icon: response.data.result,
             title: "Hata!",
             html: response.data.message.message,
             confirmButtonText: "Tamam",
            });
           }
          });
        }
       });
      }
     },
    },
    {
     name: "Ürünü Düzenle",
     action: function () {
      _this.cleanProductModalAddUpdateForm();
      _this.productModalForm._id = params.node.data._id;
      _this.productModalForm.status = params.node.data.status;
      _this.productModalForm.name = params.node.data.name;
      _this.productModalForm.productSlug = params.node.data.productSlug;
      _this.productModalForm.productCode = params.node.data.productCode;
      _this.productModalForm.type = params.node.data.type;
      _this.productModalForm.saleCount = params.node.data.saleCount;
      _this.productModalForm.webSaleStatus = params.node.data.webSaleStatus;
      _this.productModalForm.webSaleFree = params.node.data.webSaleFree;
      _this.productModalForm.description = params.node.data.description;
      _this.productModalForm.matchProduct = params.node.data.matchProduct;
      _this.productModalAddUpdateOptions.process = "update";
      _this.productModalAddUpdateOptions.title =
       params.node.data.name + " Ürününü Güncelle";
      _this.productModalAddUpdate = true;
     },
    },
    {
     name: "Kopyala",
     action: function () {
      alert("kopyala");
      //_this.gridApi.copySelectedRangeToClipboard();
     },
    },
    "separator",
    "chartRange",
   ];
   return result;
  },
  cleanProductModalAddUpdateForm() {
   this.productModalForm._id = "";
   this.productModalForm.status = "";
   this.productModalForm.name = "";
   this.productModalForm.productSlug = "";
   this.productModalForm.productCode = "";
   this.productModalForm.type = "";
   this.productModalForm.price = 0;
   this.productModalForm.saleCount = 0;
   this.productModalForm.webSaleStatus = "";
   this.productModalForm.webSaleFree = false;
   this.productModalForm.description = "";
   this.productModalForm.matchProduct = "";
  },
  searchProduct() {
   axios
    .get(process.env.VUE_APP_API_URL + "admin/vendor/product", {
     params: this.searchProductForm,
    })
    .then((response) => {
     this.rowData = response.data.productList;
     this.productGridShow = true;
    });
  },
  getProduct() {
   var _this = this;
   axios.get(process.env.VUE_APP_API_URL + "admin/product").then((response) => {
    if (response.data.productList.length > 0) {
     _this.productList.push({
      label: "Seçiniz",
      value: "",
     });
     response.data.productList.map(function (result) {
      _this.productList.push({
       label: result.name,
       value: result._id,
      });
     });
    }
   });
  },
  productAddUpdate() {
   var axiosMethod =
    this.productModalAddUpdateOptions.process == "add" ? "post" : "put";
   axios[axiosMethod](
    process.env.VUE_APP_API_URL + "admin/vendor/product",
    this.productModalForm
   ).then((response) => {
    if (response.data.result == "success") {
     Vue.swal({
      icon: response.data.result,
      title: "Başarılı",
      html: response.data.message,
      confirmButtonText: "Tamam",
     });

     if (axiosMethod == "put") {
      this.productModalAddUpdate = false;
      this.searchProduct();
     }
    } else {
     Vue.swal({
      icon: response.data.result,
      title: "Hata!",
      html:
       axiosMethod == "post"
        ? response.data.message.message
        : response.data.message,
      confirmButtonText: "Tamam",
     });
    }
   });
  },
 },
};
</script>

<style>
input[type="number"] {
 -moz-appearance: textfield !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance: none !important;
}
</style>
